import { Link } from "react-router-dom";
import logo from "./siteFiles/logo/white-transparent.png";
import "./App.css";

import { programData } from "./data/programsData.js"
import { awardsData } from "./data/awardsData.js"
import { newsData } from "./data/newsData.js"
import { partnersData } from "./data/partnersData.js"
import { teamData } from "./data/teamData.js"
import { impactData } from "./data/impactData.js"
import { involvedData } from "./data/involvedData.js"
import { toolData } from "./data/resources/tools/toolData.js"

import email from "./siteFiles/socialLogos/email.png"
import facebook from "./siteFiles/socialLogos/facebook.png"
import twitter from "./siteFiles/socialLogos/twitter.png"
import instagram from "./siteFiles/socialLogos/instagram.png"
import linkedin from "./siteFiles/socialLogos/linkedin.png"

import aboutBanner from "./siteFiles/logo/about-banner.png"
import banner from "./siteFiles/logo/banner.png"
import { Programs } from "./pages";

import ImageGallery from 'react-image-gallery';

import t2t from "./siteFiles/images/resources/toolLogos/tpt.png"
import goodreads from "./siteFiles/images/resources/toolLogos/goodreads.png"

export function Navigation(props) {

    function selected(name) {
        if (props.current === name) {
            return 'selected';
        } else {
            return "nav-item"
        }
    }


    return (
        <div className="navigation">
            <div><Link className="nav-item" to="../"><img className="logo" src={logo} /></Link></div>
            <div className="navigation-bar">
                <nav>
                    <Link className={selected("Home")} to="/">Home</Link>
                    <Link className={selected("Programs")} to="/programs">Programs</Link>
                    <Link className={selected("Resources")} to="/resources">Resources</Link>
                    <Link className={selected("Get Involved")} to="/get-involved">Get Involved</Link>
                    <Link className={selected("About")} to="/about"> About </Link>
                    <Link className={selected("News")} to="/news">News</Link>
                    <a className={selected("Donate")} rel="noreferrer" target="_blank" href="https://www.donorbox.org/pink-stream">Donate</a>
                    <a className={selected("Contact")} href="#footer">Contact</a>
                </nav>
            </div>
        </div>

    );
}

export function Main(props) {

    let name = props.name;

    function Content() {
        if (name === "Home") {
            return <Home />
        } else if (name === "Programs") {
            return <ProgramsList />
        } else if (name === "Program") {
            return<ProgramInfo id={props.id}/>
        }  else if (name === "Resources") {
            return<ResourceList />
        }
        else if (name === "About") {
            return <AboutList />
        } else if (name === "News") {
            return <NewsList count={newsData.length} />
        } else if (name === "Get Involved") {
            return <GetInvolved />
        } else {
            return <></>
        }
    }


    function Home() {
        return (<>


<div id="popup1" class="overlay">
	<div class="popup">
		<h1>Schedule a group session today</h1>
		<a class="close" href="#">&times;</a>
		<div class="content">
			<p> Educate and empower girls in your community by scheduling a Pink STREAM program </p>
		</div>
        <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfOshmiwq_HTchKeBPUCVsPR3RIXHzkWoVVLSxDFTF5I4Sf5Q/viewform?usp=sf_link"><button>Schedule ➔</button></a>
	</div>
</div>


<a href="#popup1"><img src={banner} className="banner" /></a>

            <h1>What We Do</h1>
            <p>We create educational programs for young women (K-8) to provide them early exposure in STREAM. The high quality, engaging, and appealing content we design:
                <ul>
                    <li>increses the students' interest to learn more about STREAM</li>
                    <li>boosts their confidence to be involved, to take on roles, and to become leaders in STREAM</li>
                    <li>advances their achievement in STREAM fields </li>
                </ul>
            </p>
            <a href="/about">Learn more ➔</a>
            <br /><br /><br />
            <h1>Our Impact</h1>
            <ImpactList />
            <br /><br /><br />
            <h1>Get Involved</h1>
            <a href="/get-involved">Learn more ➔</a>
            <br /><br /><br />
            <NewsList count={3} />
            <a style={{ position: "relative", bottom: "50px" }} href="/news">View more ➔</a>



            {/* <div className="sideBySide">
                <iframe width="700" height="287" src="https://www.youtube.com/embed/ZsoTNQ2YEZQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div>
                    <h2>"We are inspiring young women so they can discover themselves and change the world."</h2>
                    <h4>~Merve Rida Bayraktar
                        <br />Founder of Pink  STREAM</h4>
                </div>
            </div> */}
        </>);
    }

    function ProgramsList() {
        let listView = false;
        function ProgramItem(props) {
            
            if (listView) {
                return (
                    <div className="programItem">
                        <img className="desPhoto" src={props.image} />
                        <div>
                            <h3>{props.name}</h3>
                            <h4>{props.sessions} sessions / {props.ages} ages</h4>
                            <p>{props.description}</p>
                            <br />
                            <a className="learnMore" href={"./"+props.url}>Learn More ➔</a>
                        </div>

                    </div>
                );
            } else {
                return (
                    <div className="programItemGrid">
                        <a href={props.url}><img className="desPhotoGrid" src={props.image} /></a>
                        <a href={props.url} className="learnMore">Learn More ➔</a>
                    </div>
                );
            }
        }

        if(listView){
        return (<>
            <h1>{name}</h1>
            <ul>
                {programData.map(element => {
                    let href = "./"+element.id+"";
                    return <ProgramItem name={element.name} url={href} description={element.description} image={element.image} ages={element.ages} sessions={element.sessions} />;
                })}
            </ul>
        </>);
        } else {
            return (<>
            <h1>{name}</h1>
            <p>Check out the list of educational programs we offer. Are you interested in hosting a Pink STREAM program? Contact us from <a href="mailto: pinkstreamorg@gmail.com" target="_blank">pinkstreamorg@gmail.com</a></p>
            <div className="programsGrid">
                {programData.map(element => {
                    let href = "/programs/"+element.id+"";
                    return <ProgramItem name={element.name} url={href} description={element.description} image={element.image} ages={element.ages} sessions={element.sessions} />;
                })}
            </div>
        </>);
        }
    }

    function ProgramInfo({ id }) {
        let [myprogram] = programData.filter(e => e.id===id);
        return (<>

            <h1>{myprogram.name}</h1>
            
            <div className="programInfo">
            <div className="programDes">
            <h3>{myprogram.description}</h3>
            <p>{myprogram.longDes}</p>
            <h4>
                Age group: {myprogram.ages} <br/>
                Number of Students: {myprogram.headcount} <br/><br/>
                Scheduling:<br/>
                Workshop: {myprogram.workshop} <br/>
                Course: {myprogram.course} <br/>
                Mastery: {myprogram.mastery} <br/>
            </h4>
            <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfOshmiwq_HTchKeBPUCVsPR3RIXHzkWoVVLSxDFTF5I4Sf5Q/viewform?usp=sf_link"><button>Schedule Now</button></a>
            </div>
            <ImageGallery items={myprogram.images} />
            </div>
            
        </>);
    }

    function NewsList({ count }) {
        function NewsItem(props) {
            return (
                <div className="newsItem">
                    <a href={props.link} rel="noreferrer" target="_blank"><img className="newsPhoto" src={props.image} /></a>
                    <div>
                        <h3>{props.name}</h3>
                        <p>{props.description}</p>
                    </div>
                </div>
            );
        }

        return (<>
            <h1>In the News</h1>
            <div className="newsList">
                {newsData.map(element => {
                    return <NewsItem name={element.name} description={element.description} image={element.image} link={element.link} />;
                }).splice(0, count)}
            </div>
        </>);
    }

    function ResourceList() {
        // worksheets
        // tools
        // link to youtube channel
        // link to teachers pay teachers
        

        return (<>
            <h1>Resources</h1>
            <p>Are you passionate about STREAM and don't know where to start from? Are you an educator looking for insiring and engaging educational material for your studnets? You are at the right place. Our team composed resources to guide your journey. <br/> Do you have additional resources to share? Contact us at <a href="mailto: pinkstreamorg@gmail.com" target="_blank">pinkstreamorg@gmail.com</a></p>
            <br/>
            <h2>Worksheets</h2>
            <a href={"https://www.teacherspayteachers.com/Store/Pink-Stream"} rel="noreferrer" target="_blank"><div className="worksheets">
                <img src={t2t} style={{width: "22%"}}/>
                <p>Visit our <a href={"https://www.teacherspayteachers.com/Store/Pink-Stream"} rel="noreferrer" target="_blank">Teachers Pay Teachers Store</a> to download our free worksheets and other educational material.</p>
            </div></a>
            <br/>
            <h2>Books</h2>
            <a href={"https://www.goodreads.com/user/show/82761156-pink-stream"} rel="noreferrer" target="_blank"><div className="worksheets">
                <img src={goodreads} style={{width: "22%", padding: "10pt"}}/>
                <p>Visit our <a href={"https://www.goodreads.com/user/show/82761156-pink-stream"} rel="noreferrer" target="_blank">Goodreads Blog</a> to find amazing books about STREAM.</p>
            </div></a>
            <br/><br/>
            <ToolsList count={3} />
            
        </>);
    }

    function ToolsList({ count }) {
        function ToolItem(props) {
            return (
                <div className="toolItem"><a href={props.link} rel="noreferrer" target="_blank">
                    <a href={props.link} rel="noreferrer" target="_blank"><img className="toolLogo" src={props.image} /></a>
                    <div>
                        <h4>{props.name}</h4>
                        <p>{props.description}</p>
                    </div>
                    </a> </div> 
            );
        }

        return (<>
            <h2>Tools</h2>
            <div className="newsList">
                {toolData.map(element => {
                    return <ToolItem name={element.name} description={element.description} image={element.image} link={element.link} />;
                })}
            </div>
        </>);
    }

    function ImpactList() {
        function ImpactItem(props) {
            return (
                <div className="impactItem">
                    <h1>{props.stat}</h1>
                    <h4>{props.title}</h4>
                    <h6>{props.subtitle}</h6>
                </div>
            );
        }

        return (
            <div className="impactList">
                {
                    impactData.map((element) => {
                        return <ImpactItem stat={element.stat} title={element.title} subtitle={element.subtitle} />
                    })
                }
            </div>
        );
    }

    function AboutList() {
        function AwardsList() {
            function AwardsItem(props) {
                return (
                    <div className="awardItem">
                        <a href={props.link} rel="noreferrer" target="_blank"><img className="awardLogo" src={props.image} /></a>
                        <p>{props.name}</p>
                    </div>
                );
            }

            return (
                <div className="awardsList">
                    {awardsData.map(element => {
                        return <AwardsItem name={element.name} image={element.image} link={element.link} />;
                    })}
                </div>);
        }

        function PartnersList() {
            function PartnerItem(props) {
                return (
                    <div className="partnerItem">
                        <a href={props.link} rel="noreferrer" target="_blank"><img className="partnerLogo" src={props.image} /></a>
                        <p>{props.name}</p>
                    </div>
                );
            }

            return (
                <div className="partnersList">
                    {partnersData.map(element => {
                        return <PartnerItem image={element.image} link={element.link} />;
                    })}
                </div>);
        }

        function Team() {
            function TeamMember(props) {
                return (
                    <div className="teamMember">
                        <img className="teamImg" src={props.image}></img>
                        <div>
                            <h3><a href={props.linkedin} rel="noreferrer" target="_blank">{props.name}</a></h3>
                            <h4>{props.title}</h4>
                            <p>{props.description}</p>
                        </div>

                    </div>
                );
            }

            return (
                <div className="team">
                    {
                        teamData.map(element => {
                            return <TeamMember name={element.name} title={element.title} description={element.description} image={element.image} linkedin={element.linkedin} />
                        })}
                </div>
            );

        }



        return (
            <>
                <h1>Our Mission</h1>
                <p>Since 2018, Pink STREAM is striving to educate, motivate, empower and inspire K-8 girls in STREAM (Science, Technology, Robotics, Engineering, Arts, and Math). Our mission is to have young women gain early exposure in the fields of STREAM through fun, engaging, and educational experiences. </p>
                <img src={aboutBanner} className="banner"></img>
                <br /><br />
                <h1>Our Impact</h1>
                <p>With our wide range of programs and material, we are changing the way girls think about STREAM. </p>
                <ImpactList />
                <br /><br />
                <h1>Awards</h1>
                <AwardsList />
                <br /><br />
                <h1>Team</h1>
                <Team />
                <br /><br />
                <h1>Partners Organizations</h1>
                <p>Organizations who hosted and/or helped hosting Pink STREAM programs. </p>
                <PartnersList />
            </>
        );
    }

    function GetInvolved() {
        function InvolvedItem(props) {
            return (
                <div className="involvedItem">
                    <div>
                        <h1>{props.title}</h1>
                        <h3>{props.who}</h3>
                        {props.description}
                        <a href={props.link} target="_blank">Reach Out ➔</a>
                    </div>
                    {(props.image !== "") ? (<img src={props.image} className="image"></img>) : null}

                </div>
            );
        }

        return (
            <div className="getInvolved">
                <h1>Ways to Get Involved</h1>
                <p>Do you want to educate and empower young women in STREAM? Then you are at the right place! Below is a list of ways you can become part of our work.
                    If you have any questions feel free to <u><a>email us</a></u> with your queries.
                </p>
                {involvedData.map((e) => {
                    return <InvolvedItem title={e.title} who={e.who} description={e.description} image={e.image} link={e.link} />
                })}
            </div>
        );
    }

    return (
        <div className="main">
            <Content />
        </div>
    );


}

export function Footer() {
    return (
        <>
            <a name="footer"></a>
            <section id="footer">
                <div className="footerDiv">
                    <div className="identity">
                        <img src={logo} className="logo" />
                        <p>@Copyright Pink STREAM {new Date().getFullYear()}</p>
                    </div>
                    <div className="contact">
                        <h3>Contact us</h3>
                        <div className="socialItem">
                            <a href="mailto: pinkstreamorg@gmail.com" target="_blank"><img className="socials" src={email} /></a>
                            <p>pinkstreamorg@gmail.com</p>
                        </div>
                        <div className="socialItem">
                            <a href="https://www.facebook.com/Pink-Stream-110018567241268/" target="_blank"><img className="socials" src={facebook} /></a>
                            <a href="https://twitter.com/pinkSTREAM_" target="_blank"><img className="socials" src={twitter} /></a>
                            <a href="https://www.instagram.com/pinkstream_/" target="_blank"><img className="socials" src={instagram} /></a>
                            <p>pinkstream_</p>
                        </div>
                        <div className="socialItem">
                            <a href="https://www.linkedin.com/company/pink-stream/" target="_blank"><img className="socials" src={linkedin} /></a>
                            <p>pink-stream</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

