import img1 from "./img1.jpg"

export let images = [
    {
        original: img1,
        thumbnail: img1,
    },
];

export default images;
