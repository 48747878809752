
import cube from "../siteFiles/images/awards/cube.png"
import challenge from "../siteFiles/images/awards/challenge.png"
import davis from "../siteFiles/images/awards/davis.png"
import ibmz from "../siteFiles/images/awards/ibmz.png"
import kenan from "../siteFiles/images/awards/kenan.png"
import launch from "../siteFiles/images/awards/launch.png"
import pearlHAcks from "../siteFiles/images/awards/pearlHacks.png"
import seventeen from "../siteFiles/images/awards/seventeen.png"
import bryan from "../siteFiles/images/awards/bryan.PNG"
import eshipScholars from "../siteFiles/images/awards/eshipScholars.png"
import burch from "../siteFiles/images/awards/burch.png"
import ongor from "../siteFiles/images/awards/ongor.jpg"
import cgiu from "../siteFiles/images/awards/cgiu.jpg"
import acc from "../siteFiles/images/awards/accinventurelogo.jpg"

export const awardsData = [
    
    {
        name: "ACC InVenture Prize Finalist, 2023",
        image: acc,
        link: "https://accinventureprize.squarespace.com/"
    }, 
    {
        name: "Clinton Global Initiative University, 2023",
        image: cgiu,
        link: "https://www.clintonfoundation.org/programs/leadership-public-service/clinton-global-initiative-university/"
    }, 
    {
        name: "ONGOR Conference Public Impact Award, 2022",
        image: ongor,
        link: "https://www.ongor.org/"
    }, 
    {
        name: "Burch Fellowship, 2022",
        image: burch,
        link: "https://honorscarolina.unc.edu/fellowships/burch-fellowship/"
    }, 
    {
        name: "Project for Peace, 2021",
        image: davis,
        link: "https://www.middlebury.edu/office/projects-for-peace/projects/2021/freetechlib-pink-stream"
    }, 
    {
        name: "Eship Scholars, 2021",
        image: eshipScholars,
        link: "https://eship.unc.edu/studentspotlight/the-eship-scholars-program-at-unc-entrepreneurship-center/"
    }, 
    {
        name: "1789 Venture Fund, 2021",
        image: seventeen,
        link: "https://innovate.unc.edu/1789-student-venture-fund/"
    },
    {
        name: "IBM Z4Good Ideathon, 2021",
        image: ibmz,
        link: "https://community.ibm.com/community/user/ibmz-and-linuxone/blogs/melissa-sassi1/2021/01/19/z4good-ideathon-winners-announcement"
    },
    {
        name: "Carolina Challenge Pitch Party, 2020",
        image: challenge,
        link: "https://carolinachallenge.unc.edu/pitch-party-finalists-2020/"
    },
    {
        name: "Launch Chapel Hill Accelerator, 2020",
        image: launch,
        link: "http://www.launchchapelhill.com/blog/2020/5/26/launch-chapel-hill-welcomes-its-summer-cohort-of-unc-student-led-ventures-into-its-startup-accelerator"
    },
    {
        name: "Pearl Hacks, 2020",
        image: pearlHAcks,
        link: "https://pearlhacks.com/"
    },
    {
        name: "CUBE Social Innovation Venture, 2020",
        image: cube,
        link: "https://campusy.unc.edu/cube-announces-2020-2021-cohort/"
    }, 
    {
        name: "Robert E. Bryan Fellowship, 2020",
        image: bryan,
        link: "https://ccps.unc.edu/apples/bryan-social-innovation-fellowships-3/previous-bryan-fellows/"
    }, 
    {
        name: "Kenan Biddle Partnership, 2020",
        image: kenan,
        link: "https://publicpolicy.unc.edu/plcy_unc/the-kenan-biddle-partnership-grant/"
    }, 
]
