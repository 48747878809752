export const impactData = [
    { 
        stat: "1300+",
        title: "Students",
        subtitle: ""
    },
    { 
        stat: "150+",
        title: "Hours of Teaching",
        subtitle: ""
    },
    { 
        stat: "87%",
        title: "Students from HUGs*",
        subtitle: "(HUGs: Historically underrepresented groups)"
    },
]