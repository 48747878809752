import img1 from "./img1.jpg"
import img2 from "./img2.png"
import img3 from "./img3.jpg"
import img4 from "./img4.jpg"
import img5 from "./img5.png"
import img6 from "./img6.jpg"
import img7 from "./img7.jpg"

export let images = [
    {
        original: img1,
        thumbnail: img1,
    },
    {
        original: img2,
        thumbnail: img2,
    },
    {
        original: img3,
        thumbnail: img3,
    },
    {
        original: img4,
        thumbnail: img4,
    },
    {
        original: img5,
        thumbnail: img5,
    },
    {
        original: img6,
        thumbnail: img6,
    },
    {
        original: img7,
        thumbnail: img7,
    },
];

export default images;
