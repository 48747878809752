import scratch from "../../../siteFiles/images/resources/toolLogos/scratch.png"
import tinkercad from "../../../siteFiles/images/resources/toolLogos/tinkercad.png"
import codeorg from "../../../siteFiles/images/resources/toolLogos/code-org.png"
import madewithcode from "../../../siteFiles/images/resources/toolLogos/madewithcode.jpg"
import nasa from "../../../siteFiles/images/resources/toolLogos/NASA.png"
import codecademy from "../../../siteFiles/images/resources/toolLogos/codeacademy.png"
import engineergirl from "../../../siteFiles/images/resources/toolLogos/engineergirl.jpg"
import csfirst from "../../../siteFiles/images/resources/toolLogos/csfirst.png"
import udacity from "../../../siteFiles/images/resources/toolLogos/udacity.png"
import coursera from "../../../siteFiles/images/resources/toolLogos/coursera.png"
import edx from "../../../siteFiles/images/resources/toolLogos/edx.png"
import trinket from "../../../siteFiles/images/resources/toolLogos/trinket.png"
import microsoft from "../../../siteFiles/images/resources/toolLogos/microsoft.jpg"
import mit from "../../../siteFiles/images/resources/toolLogos/mitocw.jpg"
import csunplugged from "../../../siteFiles/images/resources/toolLogos/csunplugged.png"
import teachablemachine from "../../../siteFiles/images/resources/toolLogos/teachablemachine.png"



export const toolData = [
    {
        name: "Scratch",
        description: "Make your own interactive stories, games, animations and more.",
        image: scratch,
        link: "https://scratch.mit.edu/"
    },
    {
        name: "Code.org",
        description: "Learn coding, make your own apps, discover computer science, and more. ",
        image: codeorg,
        link: "https://code.org/"
    },
    {
        name: "Tinkercad",
        description: "Start designing your own 3D objects. You can even print them out from a 3D printer. ",
        image: tinkercad,
        link: "https://www.tinkercad.com"
    },
    {
        name: "Made w/ Code",
        description: "Improve your computer science and engineering skills with cute games.",
        image: madewithcode,
        link: "https://www.madewithcode.com/home/"
    },
    {
        name: "CS Unplugged",
        description: "Physical games and activities to learn computer science fundamentals, without a computer!",
        image: csunplugged,
        link: "https://www.csunplugged.org/en/"
    },
    {
        name: "Teachable Machine",
        description: "Start training your own AI models without any coding.",
        image: teachablemachine,
        link: "https://teachablemachine.withgoogle.com/"
    },
    {
        name: "NASA for Kids",
        description: "Explore the universe with cool games and videos from NASA.",
        image: nasa,
        link: "https://www.nasa.gov/audience/forstudents/k-4/index.html#.UtBBa_tR58E"
    },
    {
        name: "CS First",
        description: "Free Computer Science Curriculum related to any subject. Created by Google.",
        image: csfirst,
        link: "https://csfirst.withgoogle.com/s/en/home"
    },
    {
        name: "Engineer Girl",
        description: "Online Platform to Learn more about Engineering.",
        image: engineergirl,
        link: "https://www.engineergirl.org/"
    },
    {
        name: "edX",
        description: "Enroll online courses for free from top universities including Harvard, MIT, and more.",
        image: edx,
        link: "https://www.edx.org/"
    },
    {
        name: "Udacity",
        description: "Enroll online courses from top universities and companies.",
        image: udacity,
        link: "https://www.udacity.com/"
    },
    {
        name: "Coursera",
        description: "Enroll online courses from top universities like Duke, Yale, Michigan, Stanford, and more.",
        image: coursera,
        link: "https://www.coursera.org/"
    },
    {
        name: "Codecademy",
        description: "Learn coding with the online interactive platform that offers free coding classes.",
        image: codecademy,
        link: "https://www.codecademy.com/"
    },
    {
        name: "Trinket",
        description: "Watch tutorials and start coding from any computer.",
        image: trinket,
        link: "https://trinket.io/"
    },
    {
        name: "Microsoft Hacking STEM",
        description: "Take the course, try the experiments, and explore STEM.",
        image: microsoft,
        link: "https://www.microsoft.com/en-us/education/education-workshop/default.aspx"
    },
    {
        name: "MIT Open Course Ware",
        description: "Discover online courses and workshops by Massachusetts Institute of Technology.",
        image: mit,
        link: "https://ocw.mit.edu/index.htm"
    },

]