
import rida from "../siteFiles/images/team/rida.jpg"
import mina from "../siteFiles/images/team/mina.jfif"

export const teamData = [
    {
        name: "Merve Rida Bayraktar",
        title: "Founder & CEO",
        image: rida,
        description: "Enthusiastic computer scientist with a strong passion to teach technology to young girls.",
        linkedin: "https://www.linkedin.com/in/merve-rida-bayraktar/"
    }, 
    {
        name: "Mina Berra Bayraktar",
        title: "Co-Founder & CMO",
        image: mina,
        description: "Fascinated in the natural sciences, especially the human body. and its wonders. ",
        linkedin: "https://www.linkedin.com/in/mina-bayraktar-75589a1a0/"
    },
]
