import './App.css';
import React from "react";
import { Routes, Route } from 'react-router-dom';
import {
  Home,
  Programs,
  Program,
  Involved,
  Resources, 
  About,
  News,
  Awards,
  NotFound
} from "./pages";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/programs" element={<Programs />} />
        <Route path="/programs/:name" element={<Program />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/get-involved" element={<Involved />} />
        <Route path="/about" element={<About />} />
        <Route path="/news" element={<News />} />
        <Route path="/awards" element={<Awards />} />
        <Route path="/donate" to={{patname:"https://www.paypal.com/paypalme/pinkstream"}} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
