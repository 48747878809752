
import {
    Navigation,
    Main,
    Footer,
    ProgramItem
} from "./components";
import { useHistory, useParams } from 'react-router-dom'


import image from "./siteFiles/socialLogos/email.png"

export function Home(){
    return(
        <>
            <Navigation current="Home"/>
            <Main name="Home" />
            <Footer />
        </>
    );
}

export function Programs(){
    return(
        <>
            <Navigation current="Programs"/>
            <Main name="Programs"/>
            <Footer />
        </>
    );
}

export function Program(props){
    console.log("Hello");
    const { name } = useParams()
    console.log(name);

    return(
        <>
            <Navigation current="Programs"/>
            <Main name="Program" id={name}/>
            <Footer />
        </>
    );
}

export function Involved(){
    return(
        <>
            <Navigation current="Get Involved"/>
            <Main name="Get Involved" />
            <Footer />
        </>
    );
}

export function Resources(){
    return(
        <>
            <Navigation current="Resources"/>
            <Main name="Resources" />
            <Footer />
        </>
    );
}

export function About(){
    return(
        <>
            <Navigation current="About"/>
            <Main name="About" />
            <Footer />
        </>
    );
}

export function News(){
    return(
        <>
            <Navigation current="News"/>
            <Main name="News" />
            <Footer />
        </>
    );
}

export function Awards(){
    return(
        <>
            <Navigation current="Awards"/>
            <Main name="Awards" />
            <Footer />
        </>
    ); 
}

export function NotFound(){
    return(
        <>
            <Main name="Nothing here..." />
            <Footer />
        </>
    ); 
}