import sas from "../siteFiles/images/partners/sas.png"
import march from "../siteFiles/images/partners/march.png"
import doyenne from "../siteFiles/images/partners/doyenne.png"
import girls from "../siteFiles/images/partners/girls.jfif"
import technovation from "../siteFiles/images/partners/technovation.png"
import fromHeart from "../siteFiles/images/partners/from.png"
import icm from "../siteFiles/images/partners/icm.jfif"
import apex from "../siteFiles/images/partners/apex.png"
import weatherstone from "../siteFiles/images/partners/weatherstone.jpg"
import oliveChapel from "../siteFiles/images/partners/oliveChapel.jpeg"
import rcp from "../siteFiles/images/partners/rcp.png"
import sancar from "../siteFiles/images/partners/sancar.png"
import wini2021 from "../siteFiles/images/partners/wini2021.jpg"
import capa from "../siteFiles/images/partners/capa.jpg"
import canakkale from "../siteFiles/images/partners/canakkale.jpeg"
import hanan from "../siteFiles/images/partners/hanan.png"
import coalitionblack from "../siteFiles/images/partners/coalitionblackwomen.png"
import yusra from "../siteFiles/images/partners/yusra.png"

export const partnersData = [ 
    {
        name: "SAS",
        image: sas,
    }, 
    {
        name: "National Coalition for 100 Black Women",
        image: coalitionblack,
    }, 
    {
        name: "Yusra Community Center",
        image: yusra,
    }, 
    {
        name: "Weatherstone Elementary School",
        image: weatherstone,
    }, 
    {
        name: "March 4 Science",
        image: march,
    }, 
    {
        name: "Technovation",
        image: technovation,
    },
    {
        name: "Refugee Community Partnership",
        image: rcp,
    },
    {
        name: "Hanan Foundation",
        image: hanan,
    },
    {
        name: "Olive Chapel Elementary School",
        image: oliveChapel,
    }, 
    {
        name: "Doyenne Inspiration",
        image: doyenne,
    }, 
    {
        name: "Wini by Walter Magazine",
        image: wini2021,
    }, 
    //STEMlytical Global Hackathon  
    {
        name: "Girls Education International",
        image: girls,
    }, 
    {
        name: "ICM",
        image: icm,
    },
    {
        name: "Sancar Center",
        image: sancar,
    },
     {
         name: "Apex Masjid",
         image: apex,
     },
     {
        name: "From the Heart Foundation",
        image: fromHeart,
    },
    {
        name: "Capa Fen Lisesi",
        image: capa,
    },
    {
        name: "Canakkale Fen Lisesi",
        image: canakkale,
    },
]