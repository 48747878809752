import first from "../siteFiles/images/decorative/img5.jpg"
import second from "../siteFiles/images/decorative/img9.png"

export const involvedData = [
    {
        title: "Join the Team",
        who: "High School & College Students, Volunteers",
        description: <p>
            <ul>
                <li>Take Leadership Roles</li>
                <li>Create Social Media/Website content</li>
                <li>Be a part of the software development</li>
                <li>Create curriculums for teaching STREAM</li>
                <li>Teach classes on STREAM</li>
                <li>Or come with your creative ideas to make a difference!</li>
            </ul>
        </p>,
        link: "https://docs.google.com/forms/d/e/1FAIpQLSeCE_4CGHXXzBVeX2hH0OOdKMPi1ZRXcJtEVfRqzjCDDvlukg/viewform?usp=sf_link",
        image: first
    },
    {
        title: "Become a Mentor",
        who: "Professionals (in any field, especially women in STREAM)",
        description: <p>
            <ul>
                <li>Share your expertise with our team</li>
                <li>Become a role model for our students by joining as a guest speaker for our programs</li>
                <li>Guide us in our entrepreneurial journey if you have experience in non-profit business.</li>
                <li>Or come with your creative ideas to make a difference!</li>
            </ul>
        </p>,
        link: "https://docs.google.com/forms/d/e/1FAIpQLSenIlSZ1bkAXLNko4vE0LrC7k1MvDCdvI1vPv1kjBBcZN0lpw/viewform?usp=sf_link",
        image: ""
    },
    {
        title: "Partner with Us",
        who: "Community Centers and Organizations",
        description: <p>
            <ul>
                <li>Bring Pink STREAM programs into your community</li>
                <li>Have inclusive educational environments to inspire young women</li>
            </ul>
        </p>,
        link: "mailto: pinkstreamorg@gmail.com",
        image: ""
    },
    {
        title: "Sponsor and Support",
        who: "Donors, Investors, Community members",
        description: <p>
            <ul>
                <li>Drive the change</li>
                <li>Get feautured on our website and marketing material</li>
            </ul>
        </p>,
        link: "mailto: pinkstreamorg@gmail.com",
        image: ""
    },

]
