// can access the images of the banners from this link:
// 


import electronics from "../siteFiles/images/programBanners/electronics.png"
import printing from "../siteFiles/images/programBanners/printing3D.png"
import app from "../siteFiles/images/programBanners/app.png"
import women from "../siteFiles/images/programBanners/women.png"
import imageProcessing from "../siteFiles/images/programBanners/image.png"
import robotics from "../siteFiles/images/programBanners/robotics.png"
import crypto from "../siteFiles/images/programBanners/crypto.png"
import ai from "../siteFiles/images/programBanners/ai.png"
import vr from "../siteFiles/images/programBanners/vr.png"
import crossStitch from "../siteFiles/images/programBanners/crossStitch.png"


import electronicsImages from "../siteFiles/images/programs/electronics/images.js"
import printingImages from "../siteFiles/images/programs/printing/images.js"
import processingImages from "../siteFiles/images/programs/processing/images.js"
import roboticsImages from "../siteFiles/images/programs/robotics/images.js"
import womenImages from "../siteFiles/images/programs/women/images.js"
import vrImages from "../siteFiles/images/programs/vr/images.js"
import aiImages from "../siteFiles/images/programs/ai/images.js"



export const programData = [
    {
        id: "robotics",
        name: "Robotics Course",
        description: <p>
            Robots are getting closer and closer to our lives. Healthcare, farming, transportation, space, cooking and more... We use robots in all these fields.
             </p>,
        longDes: <p>In this course we will be designing, programming, and testing our robots with fun challenges, as we learn robotics applications today, and in the future.</p>,
        image: robotics,
        ages: "7-12",
        headcount: "minimum 6 - maximum 12",
        workshop: "1 session - 1 hour",
        course: "4 sessions - 1.5 hour each",
        mastery: "8 sessions - 1 hour each",
        images: roboticsImages,
    },
    {
        id: "electronics",
        name: "Electronics",
        description: <p>
            Let's build circuits and learn about electricity!
            How does electricity work? What is current, resistance, voltage? Learn the theory of electronics as we build cool projects and have fun! </p>,
        longDes: <p>
            In this program, the students will have hands on experience building circuits as they learn the theory and physics behind electronics.
            Fun experiments will both tie the learnings to real life, and make the students love electricity by creating long lasting memories.
        </p>,
        image: electronics,
        ages: "7-12",
        headcount: "minimum 6 - maximum 12",
        workshop: "1 session - 1 hour",
        course: "4 sessions - 1.5 hour each",
        mastery: "8 sessions - 1 hour each",
        images: electronicsImages
    },
    {
        id: "printing",
        name: "3D Printing and Design",
        description: <p>Imagine you could make whatever you want and print it out with a 3D printer. What would you make? A gift for a friend, a toy for your pet, or maybe a real beating heart. The possibilities are endless! </p>,
        longDes: <p>In this class, the students will gain 3D design skills, as they make their our own printer-ready designs with Tinkercad. We will 3d print the designs and watch them come to life, alongisde learning about the 3d printing technologies. We will also talk about 3d printing applications in a variety of fields: robotics, healthcare, interior design and more...</p>,
        image: printing,
        ages: "7-12",
        headcount: "minimum 6 - maximum 10",
        workshop: "1 session - 1 hour",
        course: "4 sessions - 1.5 hour each",
        mastery: "8 sessions - 1 hour each",
        images: printingImages
    },
    {
        id: "virtual-reality",
        name: "Virtual Reality",
        description: <p>Think and design the future of virtual reality! Are you ready to build your own world?</p>,
        longDes: <p>In this class, we will discuss what virtual reality is, and get to experience it with Oculus Quest 2 headsets. We will brainstorm together how the future of virtual reality will look like, thinking both the positive and negative outcomes. We will ideate projects and build them in Mozilla Hubs. We then will jump into the worlds we design and experience them in virtual reality. </p>,
        image: vr,
        ages: "12-18",
        headcount: "minimum 6 - maximum 30",
        workshop: "1 session - 2 hour",
        course: "4 sessions - 1.5 hour each",
        mastery: "8 sessions - 1 hour each",
        images: vrImages
    },
    {
        id: "artificial-intelligence",
        name: "Artificial Intelligence",
        description: <p>Everyone is talking about Artificial Intelligence, but do you really know what it means? Are you ready to build your own AI projects?</p>,
        longDes: <p>In this course we will both learn the theory of artificial intelligence and train our own artificial intelligence models to solve real world problems. The students will get to experience the power of AI, and be ready to build AI projects on their own. </p>,
        image: ai,
        ages: "13-18",
        headcount: "minimum 6 - maximum 20",
        workshop: "1 session - 1 hour",
        course: "4 sessions - 1.5 hour each",
        mastery: "8 sessions - 1 hour each",
        images: aiImages
    },
    {
        id: "app",
        name: "App Design and Prototyping",
        description: <p>We use apps every day, each with cool designs and features. Did you ever want to build your own app? Come and join us to learn how apps work and make your own app all by yourself!</p>,
        longDes: <p>In this course the students will make their own apps with Figma, an app design platform professionally used in the industry. While building their app, they will learn about the project development cycle and implement the phases of analysis, prototyping, and implementation in their development jounrey. They will also learn about mobile technologies and their place in our lives. </p>,
        image: app,
        ages: "7-12 or 13-18",
        headcount: "minimum 6 - maximum 15",
        workshop: "1 session - 1 hour",
        course: "4 sessions - 1.5 hour each",
        mastery: "8 sessions - 1 hour each",
        images: electronicsImages
    },
    {
        id: "women-scientists",
        name: "Women Scientists",
        description: <p>How many woman scientists do you know, Marie Curie, Ada Lovelace, Rachel Carson... There are many to talk about!</p>,
        longDes: <p>In this course, the students will get to know women scientists with fun activities and discussions. As they learn about role model women, they will also dream about their own stories and who they want to become.</p>,
        image: women,
        ages: "7-12",
        headcount: "minimum 6 - maximum 18",
        workshop: "1 session - 1 hour",
        course: "4 sessions - 1.5 hour each",
        mastery: "8 sessions - 1 hour each",
        images: womenImages
    },
    {
        id: "image-processing",
        name: "Image Processing",
        description: <p>Photos are a huge part of the internet, and a huge part of our lives. We capture our best moments with our camera and share with the world.</p>,
        longDes: <p>In this workshop, we learn about images, how they are processed and stored. We even make our own instagram filters with coding.</p>,
        image: imageProcessing,
        ages: "13-18",
        headcount: "minimum 6 - maximum 12",
        workshop: "1 session - 1 hour",
        course: "4 sessions - 1.5 hour each",
        mastery: "8 sessions - 1 hour each",
        images: processingImages
    }, 
    {
        id: "cryptography",
        name: "Cryptography",
        description: <p>SECRET MESSAGE: gtrh rh s hwyiwg</p>,
        longDes: <p>In this course, the students will encode and decode secret messages with exciting challenges and games. They will learn ancient and modern techniques of cryptography in the excersizes. They will also hear from people working in security, and how cryptography makes our lives safer and peaceful.</p>,
        image: crypto,
        ages: "7-12",
        headcount: "minimum 6 - maximum 12",
        workshop: "1 session - 1 hour",
        course: "4 sessions - 1.5 hour each",
        mastery: "8 sessions - 1 hour each",
        images: electronicsImages
    },
    
    {
        id: "cross-stitch",
        name: "Cross Stitching and Pixel Art",
        description: <p>Coming Soon</p>,
        longDes: <p></p>,
        image: crossStitch,
        ages: "12-18",
        headcount: "minimum 6 - maximum 12",
        workshop: "1 session - 1 hour",
        course: "4 sessions - 1.5 hour each",
        mastery: "8 sessions - 1 hour each",
        images: electronicsImages
    },

    //astronomy
    //new curriculum from books
    // cross stitch and pixel art
    // magazine art and recycling
]
