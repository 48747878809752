// can access the images of the banners from this link:
// https://www.canva.com/design/DAEjjdKhBEY/pN-z3I7n-VlrpyK9zxgxXw/edit?utm_content=DAEjjdKhBEY&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton

import grep from "../siteFiles/images/news/grep.png"
import showcase from "../siteFiles/images/news/showcase.png"
import qa from "../siteFiles/images/news/qa.png"
import ibm from "../siteFiles/images/news/ibm.png"
import gdbath from "../siteFiles/images/news/gdbath.png"
import emzingo from "../siteFiles/images/news/emzingo.png"
import launch1 from "../siteFiles/images/news/launch1.png"
import launch2 from "../siteFiles/images/news/launch2.png"
import bryan from "../siteFiles/images/news/bryan.png"
import cube from "../siteFiles/images/news/cube.png"
import pitch from "../siteFiles/images/news/pitch.png"
import dauntless from "../siteFiles/images/news/dauntless.png"
import light from "../siteFiles/images/news/light.png"
import career from "../siteFiles/images/news/career.png"
import chatChangemaker from "../siteFiles/images/news/chatwithchangemaker.jpg"
import changeMaker from "../siteFiles/images/news/changeMaker.png"
import tbj from "../siteFiles/images/news/tbj.png"
import spectrum from "../siteFiles/images/news/spectrum.png"
import eshipscholar from "../siteFiles/images/news/eshipscholar.png"
import dailytar from "../siteFiles/images/news/dailytar.png"
import wini2021 from "../siteFiles/images/news/wini2021.png"
import msamet from "../siteFiles/images/news/msamet.png"
import nc100bw from "../siteFiles/images/news/nc100bw.png"
import wini2022 from "../siteFiles/images/news/wini2022.png"
import accinventure from "../siteFiles/images/news/acc-inventure.png"
import csalumni from "../siteFiles/images/news/csalumni.png"
import alsharq from "../siteFiles/images/news/alsharq.png"
import clinton from "../siteFiles/images/news/clinton.png"
import google from "../siteFiles/images/news/google.png"
import newtech from "../siteFiles/images/news/newtech.png"

export const newsData = [
    {
        name: "Clinton Global Initiative University Graduation",
        date: "",
        description: "CGI U is a dynamic community of students dedicated to developing innovative solutions for the world's most pressing challenges. This program, founded by President Bill Clinton, empowers students worldwide to turn their ideas into commitments.",
        image: clinton,
        link: "https://www.clintonfoundation.org/programs/leadership-public-service/clinton-global-initiative-university/"
    },
    {
        name: "Beyond the Binary: UNC CS Alumni Spotlight",
        date: "",
        description: "While many grads are preparing for their next steps in industry or academia, Rida Bayraktar is pursuing social entrepreneurship to break streotypes and inspire change.",
        image: csalumni,
        link: "https://cs.unc.edu/news-article/beyond-the-binary-bayraktar-23-breaks-stereotypes-and-inspires-change/"
    },
    {
        name: "ACC Inventure Prize Finalist",
        date: "",
        description: "Rida Bayraktar is representing UNC with Pink STREAM in the pitch competition among ACC universities.",
        image: accinventure,
        link: "https://www.youtube.com/watch?v=m1N4II0Adu8&list=PLsDqYz3gtXssz8nB4J8hzFOFjifSbdh6a&index=7"
    },
    {
        name: "Presenting at Al Sharq Global Conference on Breaking Boundaries",
        date: "",
        description: "Pink STREAM is presented at the conference who brought together visionaries and changemakers from around the globe to discuss the pressing issues facing youth today, with a special focus on Al-Sharq civilization.",
        image: alsharq,
        link: "https://conference.sharqforum.org/"
    },
    {
        name: "Presenting at Black Girls in Tech Summit",
        date: "",
        description: "Pink STREAM hosted an App Design and Prototyping Workshop at the Black Girls in Tech Summit, getting the students started on creating their first apps with Figma.",
        image: nc100bw,
        link: "https://www.ncbw-qcmc.org/qcmc-blog"
    },
    {
        name: "Presenting Pink STREAM at Muslims Excelling Today",
        date: "March 2022",
        description: "Rida Bayraktar is sharing about her entrepreneurial journey and passion for girls in STEM at Muslims Excelling Today (MET).",
        image: msamet,
        link: "https://www.youtube.com/watch?v=n_2tP42HKXk&t=85s"
    },
    {
        name: "Pink STREAM Presenting at Wini 2022 with Innovate Carolina",
        date: "",
        description: "Wini is an event celebrating local women in innovation, gathering female leaders for an afternoon of introspection and inspiration.",
        image: wini2022,
        link: "https://www.youtube.com/watch?v=6jeTex3GaPk"
    },
    {
        name: "From girls in stem to chicken wings: UNC students keep pace with booming business startups",
        date: "February 2022",
        description: "Pink STREAM is part fo the large and flourishing innovation ecosiystem at UNC",
        image: dailytar,
        link: "https://www.dailytarheel.com/article/2022/02/city-student-owned-businesses"
    },
    {
        name: "Woman inspires girls to pursue STEM careers",
        date: "",
        description: "Pink STREAM's Robotics class for refugee girls is on Spectrum TV News",
        image: spectrum,
        link: "https://spectrumlocalnews.com/nc/charlotte/news/2021/11/29/motivating-more-girls-to-go-into-stem-fields#"
    },
    {
        name: "Triangle Business Journal Featuring Rida Bayraktar as one of the top 5 Innovators under 25",
        description: "The founder of Pink STREAM, Rida Bayraktar, is chosen as one of the top 5 under 25 innnovators in the Triangle, due to her work in Pink STREAM.",
        image: tbj,
        link: "https://www.bizjournals.com/triangle/inno/stories/profiles/2021/09/14/inno-5-under-25-rida-bayraktar.html"
    },
    {
        name: "Chats with Changemakers",
        description: "A discussion with Rida Bayraktar about Pink STREAM and her entrepreneurial journey.",
        image: chatChangemaker,
        link: "https://ms-my.facebook.com/InnovateUNC/videos/rida-bayraktar-is-the-founder-of-pink-stream-an-initiative-to-educate-and-inspir/625737391802420/"
    },
    {
        name: "Pink STREAM founder chosen as Student Changemaker at UNC Chapel Hill",
        description: "Rida Bayraktar is chosen as a Student Changemaker at UNC Chapel Hill for social entrepreneurship, due to her work in Pink STREAM.",
        image: changeMaker,
        link: "https://innovate.unc.edu/socialinnovation/student-changemakers/"
    },
    {
        name: "Pink STREAM presenting at Carolina Innovation Showcase",
        description: "Carolina's largest innovation showcase with many startups, inventions, and innovations.",
        image: showcase,
        link2: "https://innovate.unc.edu/innovation-showcase/",
        link: "https://www.youtube.com/watch?v=zuVeg1KVkpg"
    },
    {
        name: "Dauntless Dream Podcast on Pink STREAM",
        description: "Rida Bayraktar is joining Aniketh and Ankush, sharing her inspiration and journey in Pink STREAM.",
        image: dauntless,
        link: "https://anchor.fm/dauntlessdreaming/episodes/Episode-8-Women-in-STEM-ft--Merve-Rida-Bayraktar-epe5f3"
    },
    {
        name: "Eship Scholar Spotlight: Rida Bayraktar",
        description: "Rida Bayraktar is one of the Eship Scholars'21 through Kenan Flagler Business School Eship Center",
        image: eshipscholar,
        link: "https://eship.unc.edu/studentspotlight/qa-with-merve-rida-bayraktar-computer-science-2023/"
    },
    {
        name: "Pink STREAM presenting at Wini 2021",
        description: "Wini is an event celebrating local women in innovation, gathering female leaders for an afternoon of introspection and inspiration.",
        image: wini2021,
        link: "https://waltermagazine.com/whirl/wini-2021-recap/"
    },
    {
        name: "IBM Z4Good Ideathon Winners",
        description: "Pink STREAM's Idea for the Gender Inclusion Category won second place, check the announcement for more information.",
        image: ibm,
        link: "https://community.ibm.com/community/user/ibmz-and-linuxone/blogs/melissa-sassi1/2021/01/19/z4good-ideathon-winners-announcement"
    },
    {
        name: "UNC Chapel Hill Career Services Entrepreneurship Panel",
        description: "Rida Bayraktar is a panelist, reflecting on her work through Pink STREAM and her entrepreneurial journey.",
        image: career,
        link: "https://www.youtube.com/watch?v=tnvWxy1789I"
    },
    {
        name: "#GDTBATH: Rida Bayraktar",
        description: "Rida Bayraktar, the founder of Pink STREAM, is featured on UNC.edu due to her impactful work in Pink STREAM.",
        image: gdbath,
        link: "https://www.unc.edu/discover/gdtbath-rida-bayraktar/"
    },
    {
        name: "New Face of Tech",
        description: "Rida Bayraktar, the founder of Pink STREAM, is selected as the New Face of Tech by 1000 Dreams Fund organization and Harman.",
        image: newtech,
        link: "https://1000dreamsfund.org/success/rida-20-funded/"
    },
    {
        name: "Google Generation Scholarship",
        description: "Rida Bayraktar, the founder of Pink STREAM, is featured on UNC.edu due to her impactful work in Pink STREAM.",
        image: google,
        link: "https://students.googleblog.com/2019/07/congratulations-to-2019-google.html"
    },

    //women histry month qa
    //https://eship.unc.edu/studentspotlight/qa-with-merve-rida-bayraktar-computer-science-2023/
    {
        name: "Pink STREAM is a Carolina Pitch Party Finalist",
        description: "Check out the finalists for Carolina's largest pitch competition by UNC Kenan Flagler Business School",
        image: pitch,
        link: "https://carolinachallenge.unc.edu/pitch-party-finalists-2020/"
    },
    {
        name: "UNC Student Sees the Future of Tech Through Pink-Colored Glasses",
        description: "We are featured on the Grep Beat Magazine for our work. Definitely check out this awesome article!",
        image: grep,
        link: "https://grepbeat.com/2020/06/25/unc-student-sees-the-future-of-tech-through-pink-colored-glasses/"
    },
    //add
    //https://techstartups.com/2020/10/07/university-north-carolina-kenan-flagler-entrepreneurship-center-eship-center-announces-eship-scholars-inaugural-cohort/
    {
        name: "Pink STREAM presenting at Women International Social Impact Weekend",
        description: "Coming together with women across the globe driving change with social justice, social entrepreneurship, and social work.",
        image: emzingo,
        link: "https://www.emzingou.com/women-s-weekend"
    },
    {
        name: "Summer 2020 Venture Spotlight: Pink STREAM",
        description: "The Launch Chapel Hill Venture Spotlight is Pink STREAM! Check out the article to learn about our work and our experience in the Launch Summer accelerator.",
        image: launch2,
        link: "https://launchch.squarespace.com/blog/2020/7/20/summer-2020-venture-spotlight-pink-stream"
    },
    {
        name: "Q&A with Pink STREAM Founder Rida Bayraktar by Innovate Carolina",
        description: "Read the article to find out more about our passion and mission in working for Pink STREAM from our founder Rida Bayraktar.",
        image: qa,
        link: "https://innovate.unc.edu/qapinkstream/?utm_source=newsletter&utm_medium=email&utm_campaign=6-22-20"
    },
    {
        name: "Launch Chapel Hill Welcomes its Summer Cohort",
        description: "The UNC Student-Led Ventures are getting started with the startup accelerator of Launch Chapel Hill. Read to learn about our cohort.",
        image: launch1,
        link: "http://www.launchchapelhill.com/blog/2020/5/26/launch-chapel-hill-welcomes-its-summer-cohort-of-unc-student-led-ventures-into-its-startup-accelerator"
    },
    {
        name: "CUBE announces 2020-2021 cohort",
        description: "UNC-Chapel Hill's largest social innovation program announces the new cohort, including Pink STREAM.",
        image: cube,
        link: "https://campusy.unc.edu/cube-announces-2020-2021-cohort/"
    },
    {
        name: "Pink STREAM Presents at Light House Project Event",
        description: "Light House Project is featuring inspiring youth driving change in the community. Video of Pink STREAM's presentation is on youtube.",
        image: light,
        link: "https://www.youtube.com/watch?v=ZsoTNQ2YEZQ"
    },
    {
        name: "Bryan Fellowship announces 2020 Fellows",
        description: "Carolina Center for Public Service is awarding the fellowship to projects with social impact. Check out the great fellowship cohort.",
        image: bryan,
        link: "https://ccps.unc.edu/apples/bryan-social-innovation-fellowships-3/currentbryan-fellows/"
    },
   
]

